.public-upload .el-upload--picture-card {
    width: 115px;
    height: 115px;
}

.public-upload .el-upload--picture-card {
    line-height: 115px;
}

.public-upload {
    width: 115px;
    height: 115px;
    margin-top: 0;
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
}

.parent-files .el-upload-list--picture-card .el-upload-list__item {
    width: 115px;
    height: 115px;
}

.el-upload-list--picture-card .el-upload-list__item.public-upload {
    width: 115px;
    height: 115px;
    margin-top: 0;
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
}

.attr-upload .el-upload:nth-child(2) {
    display: none !important;
}

.el-upload-list__item {
    transition: none !important;
}

.el-textarea__inner{
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
