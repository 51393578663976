
.v-col {
    width: 100%;
    box-sizing: border-box;
    border-right: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    line-height: 20px;
    font-size: 14px;
    color: #333333;
    display: flex;
    justify-content: flex-start;

    & > div {
        padding: 10px 10px 10px 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    .v-col-label {
        width: 200px;
        background-color: #EFF5FD;
        border-right: 1px solid #E6E6E6;
    }

    .v-col-content {
        width: calc(100% - 200px);
    }
}

.v-col-half {
    width: 50%;
}
