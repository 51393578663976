
.upload-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;

    & > div {
        margin-right: 15px;
        height: 160px;
    }
}
