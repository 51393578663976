
.dialog-tab {
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid #DBDBDB;;

    li {
        cursor: pointer;
        font-size: 20px;
        line-height: 30px;
        margin: 0 20px;
        padding-bottom: 10px;
        box-sizing: border-box;
    }

    li.active {
        color: #2A76E4;
        border-bottom: 2px solid #2A76E4;
    }
}
