/* 改变主题色变量 */
$--color-primary: #2A76E4;

//$--color-success: #67c23a !default;
$--color-warning: #FF8400 !default;
$--color-danger: #E83E3E !default;
//$--color-info: #909399 !default;
/* 改变 icon 字体路径变量，必需 */
$--font-path: '~element-ui/lib/theme-chalk/fonts';

@import "~element-ui/packages/theme-chalk/src/index";

.red {
  color: #E83E3E;
}

.blue {
  color: #2A76E4;
}

.el-tree {
  .el-checkbox:last-of-type {
    margin-right: 10px;
  }
}

body {
  //min-width: 1200px;
}

* {
  margin: 0;
  padding: 0;

}
//公祭日变灰
//html{
//  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
//  -webkit-filter: grayscale(100%);
//}
ul,
li {
  list-style: none;
  padding: 0;
}

div {
  outline: none;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}


/*---滚动条默认显示样式--*/

::-webkit-scrollbar-thumb {
  background-color: hsla(220, 4%, 58%, .3);
  height: 50px;
  outline-offset: -2px;
  outline: 2px solid #fff;
  -webkit-border-radius: 4px;
  border: 2px solid #fff;
  cursor: pointer;
}


/*---鼠标点击滚动条显示样式--*/

::-webkit-scrollbar-thumb:hover {
  background-color: hsla(220, 4%, 58%, .5);
  height: 50px;
  -webkit-border-radius: 4px;
}


/*---滚动条大小--*/

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}


/*---滚动框背景样式--*/

::-webkit-scrollbar-track-piece {
  background-color: #fff;
  -webkit-border-radius: 0;
}

/*左侧菜单不显示横向滚动条*/

#menu .el-scrollbar__wrap {
  overflow-x: hidden !important;
}

/*vue transition显示 消失渐变*/

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* tab 默认样式修改 */
.content .nav-tab .el-tabs__header {
  margin-bottom: 0px !important;
}

.flex-w {
  flex-wrap: wrap;
}

.flex-a {
  display: flex;
  justify-content: space-around;
}

.flex-b {
  display: flex;
  justify-content: space-between;
}

.flex-s {
  display: flex;
  justify-content: flex-start;
}

.flex-e {
  display: flex;
  justify-content: flex-end;
}

.search-form .el-form-item {
  margin-bottom: 20px !important;
}


/*.table-tools {
    width: 100%;
    height: 48px;
    padding: 0 15px;
    background-color: #F0F8FF;
    border: 1px solid #EBEEF5;
    border-bottom: none;
    box-sizing: border-box;
    .el-button {
        margin-top: 7px;
    }
}*/

.v-table .footer {
  background-color: #F0F8FF;

  .footer-title {
    line-height: 43px;
    text-indent: 15px;
    display: inline-block;
    margin-right: 15px;
  }

  .el-form-item--mini.el-form-item {
    margin-bottom: 0;

    .el-form-item__content {
      line-height: 45px;
    }
  }
}

/*修改ui dialog空白太多*/


html .el-dialog__header {
  padding: 0px;
  background-color: #2A76E4;
  text-align: center;
  line-height: 45px;
}

html .el-dialog__header .el-dialog__title {
  color: #fff;
}

html .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}

html .el-dialog__headerbtn:focus .el-dialog__close,
html .el-dialog__headerbtn:hover .el-dialog__close {
  color: #fff;
}

html .el-dialog__headerbtn {
  top: 15px;
}

/* 删除文件上传控件的动画 */
html .el-upload-list__item {
  transition: none !important;
}

.close {
  width: 120px;
}

.v-row {
  box-sizing: border-box;
  border-left: 1px solid #E6E6E6;
  border-top: 1px solid #E6E6E6;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.handle-title {
  line-height: 18px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  text-indent: 8px;
  border-left: 4px solid #2A76E4;
  margin-bottom: 20px;
  //margin-left: 20px;
}

.el-tabs__nav-wrap::after {
  height: 1px;
  background-color: #e8e8e8;
}

#app .el-scrollbar {
  .el-scrollbar__wrap {
    overflow-x: hidden !important;
  }
}

.a-link:hover {
  color: #2A76E4;
}

#menu {
  .el-submenu__title,
  .el-menu-item {
    height: 46px;
    line-height: 46px;
  }
}

.el-popover {
  background-color: #333333b5;
  color: #fff;
}

.popper__arrow:after {
  border-top-color: #333333b5 !important;
}

.el-image-viewer__btn.el-image-viewer__close {
  color: #fff !important;
  background-color: #606266 !important;
  text-align: center;
  line-height: 40px;
  font-size: 25px;

  .el-icon-circle-close:before {
    content: "\E6DB";
  }
}

.v-input-number {
  width: 100%;

  ::v-deep input.el-input__inner {
    text-align: left;
  }
}
